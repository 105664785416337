import { Links, Scripts, LiveReload } from '@ubo/losse-sjedel'
import { LosseLink, AdminPanel } from '@ubo/losse-sjedel'

export default function PageNotFound() {
  return (
    <html lang="nl">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body>
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div>
            <div className="flex justify-center mb-10">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="214px"
                height="76.066px"
                viewBox="0 0 214 76.066"
                enableBackground="new 0 0 214 76.066"
                xmlSpace="preserve"
              >
                <g>
                  <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="461.3418"
                    y1="-942.8098"
                    x2="505.5332"
                    y2="-942.8098"
                    gradientTransform="matrix(1 0 0 -1 -403 -916.6665)"
                  >
                    <stop offset={0} style={{ stopColor: '#009F4F' }} />
                    <stop offset={1} style={{ stopColor: '#00772A' }} />
                  </linearGradient>
                  <polygon
                    fill="url(#SVGID_1_)"
                    points="58.342,27.809 71.675,14.095 102.533,14.095 102.533,38.191 63.484,38.191 63.484,27.808
		"
                  />
                  <path
                    fill="#1D1D1B"
                    d="M102.962,38.621H63.057V28.237l-5.729,0.001l14.167-14.571h31.467V38.621L102.962,38.621z M63.914,37.762
		h38.191V14.524h-30.25l-12.5,12.856l4.558-0.001L63.914,37.762L63.914,37.762z"
                  />
                </g>
                <g>
                  <polygon fill="#1D1D1B" points="102.533,26.572 82.92,26.572 71.363,14.39 71.986,13.801 83.289,25.715 102.533,25.715 	" />
                </g>
                <linearGradient
                  id="SVGID_2_"
                  gradientUnits="userSpaceOnUse"
                  x1="511.7246"
                  y1="-938.238"
                  x2="540.2002"
                  y2="-938.238"
                  gradientTransform="matrix(1 0 0 -1 -403 -916.6665)"
                >
                  <stop offset={0} style={{ stopColor: '#08A1DC' }} />
                  <stop offset={1} style={{ stopColor: '#0076A6' }} />
                </linearGradient>
                <polyline
                  fill="url(#SVGID_2_)"
                  points="137.2,25.524 133.391,19.048 130.915,19.048 128.248,14.095 123.486,14.095 121.009,10.667
	114.725,10.667 112.248,14.476 109.2,14.572 108.725,32.476 137.2,28.381 "
                />
                <g>
                  <polygon
                    fill="#43B7EA"
                    points="135.497,24.884 132.668,20.048 130.275,20.048 127.609,15.048 122.736,15.048 120.498,11.761
		114.291,11.761 114.291,10.905 120.951,10.905 123.188,14.191 128.125,14.191 130.792,19.191 133.16,19.191 136.236,24.452 	"
                  />
                </g>
                <g>
                  <polygon
                    fill="#1D1D1B"
                    points="108.282,32.973 108.782,14.157 112.01,14.054 114.491,10.238 121.229,10.238 123.705,13.667
		128.504,13.667 131.171,18.621 133.637,18.621 137.57,25.306 136.831,25.743 133.146,19.476 130.659,19.476 127.992,14.524
		123.268,14.524 120.791,11.095 114.957,11.095 112.484,14.898 109.618,14.987 109.166,31.98 137.141,27.958 137.262,28.805 	"
                  />
                </g>
                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="512.2002"
                  y1="-946.8098"
                  x2="557.0566"
                  y2="-946.8098"
                  gradientTransform="matrix(1 0 0 -1 -403 -916.6665)"
                >
                  <stop offset={0} style={{ stopColor: '#08A1DC' }} />
                  <stop offset={1} style={{ stopColor: '#0076A6' }} />
                </linearGradient>
                <polygon
                  fill="url(#SVGID_3_)"
                  points="109.2,38.191 138.818,38.191 154.057,22.095 138.057,22.095 135.201,26.143 109.2,26.143 "
                />
                <g>
                  <polygon
                    fill="#43B7EA"
                    points="138.427,38.487 137.807,37.896 151.938,23.063 137.677,23.063 137.677,22.207 153.938,22.207 	"
                  />
                </g>
                <g>
                  <polygon fill="#43B7EA" points="134.992,27.142 109.2,27.142 109.2,26.287 135.604,26.287 	" />
                </g>
                <g>
                  <path
                    fill="#1D1D1B"
                    d="M139.004,38.621h-30.232V25.715h26.207l2.856-4.048h17.218L139.004,38.621z M109.628,37.762h29.007
		l14.428-15.238h-14.783l-2.855,4.048H109.63L109.628,37.762L109.628,37.762z"
                  />
                </g>
                <g>
                  <rect x="72.65" y="14.167" fill="#48A634" width="29.882" height="0.856" />
                </g>
                <g>
                  <g>
                    <polygon fill="#48A634" points="102.533,27.142 83.438,27.142 82.688,26.287 102.533,26.287 		" />
                  </g>
                </g>
                <g>
                  <path
                    fill="#1D1D1B"
                    d="M102.962,38.621H63.057V28.237l-5.729,0.001l14.167-14.571h31.467V38.621L102.962,38.621z M63.914,37.762
		h38.191V14.524h-30.25l-12.5,12.856l4.558-0.001L63.914,37.762L63.914,37.762z"
                  />
                </g>
                <g>
                  <polygon fill="#1D1D1B" points="102.533,26.572 82.92,26.572 71.363,14.39 71.986,13.801 83.289,25.715 102.533,25.715 	" />
                </g>
                <g>
                  <path
                    fill="#1D1D1B"
                    d="M104.248,50.287H83.009v-7.904h-6.667v7.904H55.008V0h21.333v7.904h6.667V0h21.239L104.248,50.287
		L104.248,50.287z M85.294,48.001h16.667V2.287H85.294v7.904H74.057V2.287H57.294v45.715h16.763v-7.905h11.237V48.001L85.294,48.001
		z"
                  />
                </g>
                <g>
                  <path
                    fill="#1D1D1B"
                    d="M156.82,50.287h-21.239v-7.904h-6.666v7.904h-21.333V0h21.333v7.904h6.666V0h21.239V50.287z
		 M137.867,48.001h16.667V2.287h-16.667v7.904h-11.238V2.287h-16.763v45.715h16.763v-7.905h11.238V48.001z"
                  />
                </g>
                <g>
                  <path d="M0,64.844h3.34v3.999h7.042v-3.999h3.341v11.224h-3.341V71.82H3.34v4.247H0V64.844z" />
                  <path d="M15.98,64.844h11.918v2.604H19.32v1.798h8.125v2.325H19.32v1.799h8.771v2.697H15.98V64.844z" />
                  <path d="M30.29,64.844h3.341v11.224H30.29V64.844z" />
                  <path
                    d="M36.068,64.844h5.176l6.275,8.154h0.028v-8.154h3.343v11.224h-5.178l-6.273-8.153h-0.03v8.153h-3.341V64.844L36.068,64.844
		z"
                  />
                  <path d="M53.146,64.844h11.916v2.604h-8.576v1.798h8.125v2.325h-8.125v1.799h8.771v2.697h-12.11V64.844L53.146,64.844z" />
                  <path d="M67.275,64.844h5.176l6.275,8.154h0.028v-8.154h3.341v11.224h-5.177l-6.274-8.153h-0.029v8.153h-3.34V64.844z" />
                  <path
                    d="M91.667,72.144c0.03-1.767,0.497-2.4,2.362-2.447v-0.031c-1.053-0.683-1.113-1.038-1.113-2.372
		c0-2.155,0.527-2.573,3.928-2.573h2.588c3.793,0,4.424,0.496,4.424,3.146v0.619h-2.889v-0.542c0-0.619-0.376-0.898-1.49-0.898
		h-2.302c-0.933,0-1.188,0.124-1.188,0.682c0,0.437,0.074,0.591,0.723,0.933l5.434,2.884v-1.489h2.707v1.581
		c0,0.636-0.061,0.931-0.119,1.287l1.939,1.039l-0.918,2.107l-1.911-1.021c-0.663,1.054-2.212,1.146-3.626,1.146h-4.499
		c-2.452,0-4.048-0.65-4.048-3.178L91.667,72.144L91.667,72.144z M101.086,73.772c0.061,0,0.075-0.03,0.075-0.094l-5.132-2.697
		c-0.12-0.062-0.24-0.078-0.496-0.078c-0.481,0-0.889,0.14-0.889,1.41c0,1.193,0.543,1.458,1.655,1.458L101.086,73.772
		L101.086,73.772z"
                  />
                  <path d="M115.186,64.844h3.34v3.999h7.043v-3.999h3.342v11.224h-3.342V71.82h-7.043v4.247h-3.34V64.844z" />
                  <path
                    d="M130.924,68.782c0-3.286,1.79-4.062,4.801-4.062h5.356c3.01,0,4.8,0.775,4.8,4.062v3.349c0,3.286-1.79,4.062-4.8,4.062
		h-5.356c-3.011,0-4.801-0.774-4.801-4.062V68.782z M134.354,71.059c0,1.629,0.332,2.248,1.912,2.248h4.273
		c1.58,0,1.911-0.619,1.911-2.248V69.85c0-1.395-0.331-2.248-2.091-2.248h-3.912c-1.762,0-2.092,0.854-2.092,2.248L134.354,71.059
		L134.354,71.059z"
                  />
                  <path
                    d="M147.896,64.844H157c3.175,0,3.941,1.644,3.941,3.845v0.961c0,1.675-0.705,3.597-3.069,3.597h-6.636v2.82h-3.34V64.844
		L147.896,64.844z M151.238,70.456h5.191c0.781,0,1.084-0.527,1.084-1.193v-0.435c0-0.761-0.363-1.193-1.369-1.193h-4.906V70.456
		L151.238,70.456z"
                  />
                  <path
                    d="M162.748,64.844h5.131l3.777,7.224l3.777-7.224h5.131v11.224h-3.25v-8.062h-0.03l-4.378,8.062h-2.499l-4.38-8.062h-0.029
		v8.062h-3.25V64.844L162.748,64.844L162.748,64.844L162.748,64.844z"
                  />
                  <path
                    d="M187.65,64.844h4.351l5.988,11.224h-3.716l-1.039-1.983h-6.771l-0.979,1.983h-3.73L187.65,64.844z M187.712,71.665h4.272
		l-2.166-4.218L187.712,71.665z"
                  />
                  <path
                    d="M199.177,64.844h5.177l6.274,8.154h0.03v-8.154H214v11.224h-5.176l-6.275-8.153h-0.029v8.153h-3.342L199.177,64.844
		L199.177,64.844z"
                  />
                </g>
              </svg>
            </div>

            <div className="max-w-max mx-auto">
              <main className="sm:flex">
                <p className="text-3xl font-bold font-hh-heading text-hh-blue sm:text-5xl pt-8">404</p>
                <div className="sm:ml-6">
                  <div className="sm:border-l sm:border-hh-emperor sm:pl-6">
                    <div className="content-lg">
                      <h1>Pagina niet gevonden</h1>
                      <p className="font-hh-paragraph font-light">Controleer de URL in de adresbalk en probeer het opnieuw.</p>
                    </div>
                  </div>
                  <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                    <LosseLink to="/" className="button-blue">
                      Ga naar home
                    </LosseLink>
                    <LosseLink to="/contact" className="button-mine-shaft-opaque">
                      Contact
                    </LosseLink>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
        <Scripts />
        <LiveReload />
        <AdminPanel />
      </body>
    </html>
  )
}
