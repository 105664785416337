import { Root, rootLoader as loader, useLoaderData } from '@ubo/losse-sjedel';
import type { LinksFunction, RootProps } from '@ubo/losse-sjedel';
import PageNotFound from '~/components/layout/PageNotFound';
import styles from '~/global.css';
import FlexContent from './graphql/FlexContent';
import Queries from './graphql/Queries';
import PostTypes from './graphql/PostTypes';

export const flexData = {
  flexContent: FlexContent,
  queries: Queries,
  postTypes: PostTypes
};

export const links: LinksFunction = () => {
  return [
  {
    href: 'https://use.typekit.net',
    rel: 'preconnect',
    crossOrigin: 'anonymous'
  },
  {
    href: 'https://p.typekit.net',
    rel: 'preconnect',
    crossOrigin: 'anonymous'
  },
  {
    href: 'https://use.typekit.net/eeh2mjd.css',
    rel: 'preload',
    as: 'style'
  },
  {
    href: 'https://use.typekit.net/eeh2mjd.css',
    rel: 'stylesheet'
  },
  {
    rel: 'preload',
    as: 'style',
    href: styles
  },
  {
    href: '/static/nprogress.min.css',
    rel: 'stylesheet',
    async: true
  },
  {
    rel: 'preload',
    as: 'style',
    href: styles
  },
  {
    href: styles,
    rel: 'stylesheet'
  },
  {
    href: '/manifest.json',
    rel: 'manifest'
  },
  {
    rel: 'icon',
    href: '/favicon.png',
    type: 'image/png'
  }];

};

export { loader };

export function CatchBoundary() {
  return <PageNotFound />;
}

export default function App() {
  const data = useLoaderData<RootProps>();

  return (
    <Root
      {...data}
      headChildren={
      <>
          <script
          dangerouslySetInnerHTML={{
            __html: `
if ('serviceWorker' in navigator) {
navigator.serviceWorker.register("/sw.js").then(
(registration) => {
  console.log("Service worker registration succeeded:", registration);
},
(error) => {
  console.error('Service worker registration failed:', error);
},
);
}
`
          }} />

        </>} />);



}